import { useTranslation } from 'react-i18next';
import DashboardPagesTemplate from '../../templates/DashboardPagesTemplate';


const Home = () => {

  const { t } = useTranslation();


  return (
    <>
      <DashboardPagesTemplate>
        <h1>Home Page</h1>
      </DashboardPagesTemplate>
    
    </>
  )

}

export default Home;