import { HelmetProvider } from 'react-helmet-async';
import MainRoute from './Router';

function App() {

  const helmetContext = {};

  return (

    <HelmetProvider context={helmetContext}>
      <MainRoute />
    </HelmetProvider >

  );
}

export default App;
