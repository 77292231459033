import styled from 'styled-components';
import UserModuleTemplate from "../../templates/UserModuleTemplate";
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useState } from 'react';
import { Link } from 'react-router-dom';
//import useUserLoginHook from '../../Hook/UserHook/Login';
//import userLoginHook from '../../Hook/UserHook/Login';
import { useMutation } from '@tanstack/react-query';
import getUserLoginApi from '../../Api/UserModuleApi/LoginApi';

// Style
const LoginMainWrapper = styled.div`
    width: 100%;

    .form-text {
        width: 100%;
        display: inline-block;
        text-align: right;
    }
`;

const UserLoginPage = () => {

    // State
    const [isLoginFormValidated, setIsLoginFormValidated] = useState(false);
    const [userID, setUserId] = useState<string>('');
    const [userPassword, setUserPassword] = useState<string>('');

    const mutation = useMutation({
        mutationFn: getUserLoginApi,
        onSuccess: () => {
            // Do something on success
            console.info('Item created successfully', );
        },
        onError: (error) => {
            // Handle the error
            console.info('Error creating item:', error);
        },
    });

    const handleLoginFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else {
            mutation.mutate({userID: userID, userPass:userPassword});
            console.info("Data ==>", mutation.isSuccess, mutation.isError, mutation.data);
        }
        setIsLoginFormValidated(true);
    }
    return (
        <>
            <UserModuleTemplate templateType={1} templateTitle={`Welcome Back to Relaxation!`} templateSubTitle={`Kindly Sign In to Proceed`}>
                <LoginMainWrapper>
                    <Form noValidate validated={isLoginFormValidated} onSubmit={(e) => handleLoginFormSubmit(e)}>
                        <FloatingLabel controlId="userID" label="Email address" className="mb-3">
                            <Form.Control type="email" placeholder="" value={userID} onChange={(e) => setUserId(e.target.value)} required />
                            <Form.Control.Feedback type="invalid" tooltip>Please enter email address.</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel controlId="userPassword" label="Password" className='mb-3'>
                            <Form.Control type="password" placeholder="Password" value={userPassword} onChange={(e) => setUserPassword(e.target.value)} required />
                            <Form.Control.Feedback type="invalid" tooltip>Please enter password.</Form.Control.Feedback>
                        </FloatingLabel>
                        {/* <Form.Text><Link to="/forgot-password">Forgot Password?</Link></Form.Text> */}
                        <Button variant="primary" type="submit" size='lg' className='w-100'>Sign In</Button>
                    </Form>
                </LoginMainWrapper>
            </UserModuleTemplate>

        </>
    )
}

export default UserLoginPage;