
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import errorImage from '../../Assets/img/unplugged.svg';


const ErrorPage = () => {
  const { t } = useTranslation();
  return <>

    <div className="px-5 text-center d-flex justify-content-center align-items-center">
      <div className="inner-wrapper">
        <Container>
          <h1>{t("404")}</h1>
          <h2>{t("Page not found")}</h2>
        </Container>
        <div className="img-wrapper my-5">
          <img src={errorImage} alt="404 Error" className="img-fluid" />
        </div>
        <Container>
          <p>{t("We're sorry, the page you requested could not be found.")} <br />{t("Please go back to the homepage.")}</p>
          <Link to="/" className="btn btn-dark">{t("Go to Home")}</Link>
        </Container>
      </div>
    </div>

  </>

}

export default ErrorPage;