import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';

const LanguageButtonWrapper = styled.button.attrs(() => ({
    className: 'btn btn-outline-primary',
}))`
    background-color: rgba(var(--bs-primary-rgb), 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    overflow: hidden;
`;

const LanguageButtonIcon = styled.div`
    width: 3.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    background-color: rgba(var(--bs-primary-rgb), 1);
    color: var(--bs-white);
`;
const LanguageButtonText = styled.div`
    padding-inline: 1rem;
`;

const LanguageButton = () => {

    // States Declaration
    const [showLanguageListModal, setShowLanguageListModal] = useState<boolean>(false);

    // Language Modal Functions
    const handleLanguageListModalShow = () => {
        setShowLanguageListModal(true);
    }
    const handleLanguageListModalHide = () => {
        setShowLanguageListModal(false);
    }


    return (
        <>
            <LanguageButtonWrapper onClick={() => handleLanguageListModalShow()}>
                <LanguageButtonIcon>EN</LanguageButtonIcon>
                <LanguageButtonText>English</LanguageButtonText>
            </LanguageButtonWrapper>

            <Modal show={showLanguageListModal} onHide={handleLanguageListModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleLanguageListModalHide}>Close</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default LanguageButton;