import "../../Assets/scss/components/header.scss";
import dashboardLogo from "../../Assets/img/enabledat-logo.svg";

import { Button, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";

const DashboardHeaderBlock = () => {
    return (
        <>
            <Navbar expand={`xl`} className="enabled_dashboard_app-header">

                <div className="enabled_dashboard_app-header-left">
                    <div className="enabled_dashboard_app-sidebar-header-logo d-lg-none">
                        <Link to="/">
                            <img src={dashboardLogo} alt="" />
                        </Link>
                    </div>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
                </div>

                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-xl`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                    placement="end"
                    className="sidebar-navigation"
                >
                    <Offcanvas.Header closeButton>
                        <div className="enabled_dashboard_app-sidebar-header">
                            <div className="enabled_dashboard_app-sidebar-header-logo d-none">
                                <Link to="/">
                                    <img src={dashboardLogo} alt="" />
                                </Link>
                            </div>
                            <div className="enabled_dashboard_app-sidebar-header-add-domain-btn">
                                <Button variant="success" className="w-100 enabled_dashboard_app-icon-btn">
                                    <i className="material-symbols-outlined">add_circle</i>
                                    <span>Add New Domain</span>
                                </Button>
                            </div>
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav>
                            <Nav.Link as={Link} to="#action1" className="active">
                                <i className="material-symbols-outlined">add_circle</i>
                                <span>Dashboard</span>
                            </Nav.Link>
                            <Nav.Link as={Link} to="#action1">
                                <i className="material-symbols-outlined">add_circle</i>
                                <span>Your Domains</span>
                            </Nav.Link>
                            {/* <Nav.Link as={Link} to="#action1">
                                <i className="material-symbols-outlined">add_circle</i>
                                <span>Widget Settings</span>
                            </Nav.Link> */}
                            <Nav.Link as={Link} to="#action1">
                                <i className="material-symbols-outlined">add_circle</i>
                                <span>Manage Plans</span>
                            </Nav.Link>
                            <Nav.Link as={Link} to="#action1">
                                <i className="material-symbols-outlined">add_circle</i>
                                <span>Orders</span>
                            </Nav.Link>
                        </Nav>
                        <div className="enabled_dashboard_app-sidebar-user-wrapper">
                            <div className="enabled_dashboard_app-sidebar-user-info-box">
                                <div className="enabled_dashboard_app-sidebar-user-info-box-img"></div>
                                <div className="enabled_dashboard_app-sidebar-user-info-box-text">
                                    <div className="enabled_dashboard_app-sidebar-user-name fs-5">John Due</div>
                                    <div className="enabled_dashboard_app-sidebar-user-role">CEO/Founder</div>
                                </div>
                            </div>
                            <div className="enabled_dashboard_app-sidebar-user-navigation">
                                <Nav.Link as={Link} to="/profile" className="active">
                                    <i className="material-symbols-outlined">add_circle</i>
                                    <span>Profile</span>
                                </Nav.Link>
                                <Nav.Link as={Link} to="#action1">
                                    <i className="material-symbols-outlined">add_circle</i>
                                    <span>Support</span>
                                </Nav.Link>
                                <Button variant="danger" className="enabled_dashboard_app-icon-btn w-100">
                                    <i className="material-symbols-outlined">add_circle</i>
                                    <span>Logout</span>
                                </Button>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
        </>
    )
}

export default DashboardHeaderBlock;