import styled from 'styled-components';
import UserModuleTemplate from "../../templates/UserModuleTemplate";
import { Alert, Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useState } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentCheckoutForm from '../../Components/paymentCheckoutForm';

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey ? stripeKey : ``);


// Style
const SignUpMainWrapper = styled.div`
    width: 100%;
`;
const PlanWrapper = styled.div`
    
`;
const PlanBox = styled.button`
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(var(--bs-primary-rgb), 0.1);
    border: 1px solid rgba(var(--bs-primary-rgb), 0.5);
    border-radius: 10px;
    margin-bottom: 1.5rem;
    position: relative;
    width: 100%;

    &.active {
        background-color: rgba(var(--bs-success-rgb), 0.2);
        border: 1px solid rgba(var(--bs-success-rgb), 0.9);
    }
`;
const PlanBoxPopularLabel = styled.div`
    position: absolute;
    top: -0.9rem;
    left: 1.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    font-size: 13px;
    line-height: 1;
    background-color: rgba(var(--bs-danger-rgb), 1);
    color: var(--bs-white);
    font-weight: 500;
`;
const PlanBoxLeft = styled.div`
    
`;
const PlanBoxLeftTitle = styled.div.attrs(() => ({
    className: `h5`
}))`
    margin-bottom: 0.2rem;
`;
const PlanBoxLeftText = styled.div`
    font-size: 14px;
`;
const PlanBoxRight = styled.div`
    
`;

const PlanBoxRightPrice = styled.div.attrs(() => ({
    className: `h4 text-primary`
}))`
    margin-bottom: 0;

    span {
        font-size: 1rem;
        font-weight: 500;
        color: var(--bs-black);
    }
`;



const SignUpPage = () => {

    // State define
    const [clientSecret, setClientSecret] = useState("");

    const [formStepCount, setFormStepCount] = useState<number>(1);

    // Form Step 01
    const [form01Validated, setForm01Validated] = useState(false);
    const [trialWebsite, setTrialWebsite] = useState<string>("");
    const [trialWebsiteTitle, setTrialWebsiteTitle] = useState<string>("");
    const [trialUserName, setTrialUserName] = useState<string>("");
    const [trialUserEmail, setTrialUserEmail] = useState<string>("");
    const [trialUserPhone, setTrialUserPhone] = useState<string>("");

    // Form Step 02
    const [planID, setPlanID] = useState<string>("");
    const [isPlanSelectionError, setIsPlanSelectionError] = useState<boolean>(false);


    // Step 01
    const handleTrialWebsiteStepForm = (e: React.FormEvent<HTMLFormElement>) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            setFormStepCount(2);
        }
        setForm01Validated(true);
    }

    // Step 02
    const handleWebsitePlanStepForm = () => {
        if (planID === "") {
            setIsPlanSelectionError(true);
        } else {
            setIsPlanSelectionError(false);
            setFormStepCount(3);
        }
    }


    return (
        <>
            <UserModuleTemplate
                templateTitle={`${formStepCount === 1 ? `Get started free trial` : formStepCount === 2 ? `Select Plan` : formStepCount === 3 ? `Make a Payment` : `Thank You`}`}
                templateSubTitle={`${formStepCount === 1 ? `10 days free trial` : formStepCount === 2 ? `10 days free trial · Cancel Anytime` : formStepCount === 3 ? `Enabled.at is PCI-DSS Compliant` : `Copy the script and insert it into website footer or footer.`}`}
                formStep={formStepCount}
                hideFooter={formStepCount === 1 ? false : true}
                templateType={2}
            >
                <SignUpMainWrapper>
                    {formStepCount === 1 ? (
                        <>
                            <Form noValidate validated={form01Validated} onSubmit={(e) => handleTrialWebsiteStepForm(e)}>
                                <FloatingLabel controlId="trialWebsite" label="Website URL" className="mb-3">
                                    <Form.Control type="url" placeholder="https://enabled.at" value={trialWebsite} onChange={(e) => setTrialWebsite(e.target.value)} required />
                                    <Form.Control.Feedback type="invalid" tooltip>Please add website URL Like https://enabled.at or http://enabled.at.</Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel controlId="trialWebsiteTitle" label="Website Title" className='mb-3'>
                                    <Form.Control type="text" placeholder="Enable Web Accessibility" value={trialWebsiteTitle} onChange={(e) => setTrialWebsiteTitle(e.target.value)} required />
                                    <Form.Control.Feedback type="invalid" tooltip>Please enter your website title.</Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel controlId="trialUserName" label="Full Name" className='mb-3'>
                                    <Form.Control type="text" placeholder="Enable Web Accessibility" value={trialUserName} onChange={(e) => setTrialUserName(e.target.value)} required />
                                    <Form.Control.Feedback type="invalid" tooltip>Please enter your full name.</Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel controlId="trialUserEmail" label="Email address" className="mb-3">
                                    <Form.Control type="email" placeholder="name@example.com" value={trialUserEmail} onChange={(e) => setTrialUserEmail(e.target.value)} required />
                                    <Form.Control.Feedback type="invalid" tooltip>Please enter your valid email.</Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel controlId="trialUserPhone" label="Phone Number" className="mb-3">
                                    <Form.Control type="tel" placeholder="" value={trialUserPhone} onChange={(e) => setTrialUserPhone(e.target.value)} required />
                                    <Form.Control.Feedback type="invalid" tooltip>Please enter your valid phone number.</Form.Control.Feedback>
                                </FloatingLabel>
                                <Button variant="primary" type="submit" size='lg' className='w-100'>Sign Up</Button>
                            </Form>
                        </>
                    ) : formStepCount === 2 ? (
                        <>
                            <PlanWrapper>
                                <Alert show={isPlanSelectionError} variant="danger">Please select your plan.</Alert>

                                <PlanBox className={`${planID === "price_1PVxRTL7T44M6DHYSfSPyENw" ? `active` : ``}`} onClick={() => { setPlanID("price_1PVxRTL7T44M6DHYSfSPyENw"); setIsPlanSelectionError(false); }}>
                                    <PlanBoxLeft>
                                        <PlanBoxLeftTitle>Monthly Plan</PlanBoxLeftTitle>
                                        <PlanBoxLeftText>Description Text</PlanBoxLeftText>
                                    </PlanBoxLeft>
                                    <PlanBoxRight>
                                        <PlanBoxRightPrice>$22.99<span>/monthly</span></PlanBoxRightPrice>
                                    </PlanBoxRight>
                                </PlanBox>
                                <PlanBox className={`${planID === "price_1PVxRTL7T44M6DHYlSu3ZzZx" ? `active` : ``}`} onClick={() => { setPlanID("price_1PVxRTL7T44M6DHYlSu3ZzZx"); setIsPlanSelectionError(false); }}>
                                    <PlanBoxPopularLabel>Popular</PlanBoxPopularLabel>
                                    <PlanBoxLeft>
                                        <PlanBoxLeftTitle>Yearly Plan</PlanBoxLeftTitle>
                                        <PlanBoxLeftText>Description Text</PlanBoxLeftText>
                                    </PlanBoxLeft>
                                    <PlanBoxRight>
                                        <PlanBoxRightPrice>$222.99<span>/monthly</span></PlanBoxRightPrice>
                                    </PlanBoxRight>
                                </PlanBox>
                                <PlanBox className={`${planID === "price_1PVxraL7T44M6DHYkXDkR6lB" ? `active` : ``}`} onClick={() => { setPlanID("price_1PVxraL7T44M6DHYkXDkR6lB"); setIsPlanSelectionError(false); }}>
                                    <PlanBoxLeft>
                                        <PlanBoxLeftTitle>2 Yearly Plan</PlanBoxLeftTitle>
                                        <PlanBoxLeftText>Description Text</PlanBoxLeftText>
                                    </PlanBoxLeft>
                                    <PlanBoxRight>
                                        <PlanBoxRightPrice>$422.99<span>/monthly</span></PlanBoxRightPrice>
                                    </PlanBoxRight>
                                </PlanBox>

                                <Button variant="primary" type="submit" size='lg' className='w-100' onClick={() => handleWebsitePlanStepForm()}>Make a Payment</Button>

                            </PlanWrapper>
                        </>
                    ) : formStepCount === 3 ? (
                        <>
                            <Elements stripe={stripePromise}>
                                <PaymentCheckoutForm
                                    trialWebsite={trialWebsite}
                                    trialWebsiteTitle={trialWebsiteTitle}
                                    trialUserEmail={trialUserEmail}
                                    trialUserName={trialUserName}
                                    trialUserPhone={trialUserPhone}
                                    planID={planID}
                                />
                            </Elements>
                        </>
                    ) : (
                        <>

                        </>
                    )}
                </SignUpMainWrapper>
            </UserModuleTemplate>

        </>
    )
}

export default SignUpPage;