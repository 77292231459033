import { Navigate } from 'react-router-dom';

interface PropTypes {
  userID: number;
  children: React.ReactElement<any, any>
}

const ProtectedRoute = ({ userID, children }: PropTypes) => {
  if (userID === 0) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute