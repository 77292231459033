const baseURL = `${process.env.REACT_APP_BASE_URL}/api/user`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

interface LoginObjPropType {
    userID: string,
    userPass: string
}

const getUserLoginApi = async (logInObj: LoginObjPropType): Promise<any> => {
    
    const url = `${baseURL}/login`;

    /* const data = new FormData()
    data.append('email', userID);
    data.append('password', userPass); */
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body:JSON.stringify({
                email: logInObj.userID,
                password: logInObj.userPass
            })
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

export default getUserLoginApi;