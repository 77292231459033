import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import styled from 'styled-components';
//import signUpService from "../../Services/UserModuleService/SignUpService";

const PaymentWrapper = styled.div`
    
`;
const PaymentWrapperBox = styled.div`
    background-color: rgba(var(--bs-primary-rgb), 0.1);
    border: 1px solid rgba(var(--bs-primary-rgb), 0.5);
    border-radius: 10px;
    margin-bottom: 1.5rem;
`;
const PaymentWrapperBoxTitle = styled.div`
    padding: 1rem;
    border-bottom: 1px solid rgba(var(--bs-primary-rgb), 0.5);
    font-weight: 600;
`;
const OrderSummary = styled.div`
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const PlanBoxLeft = styled.div`
    
`;
const PlanBoxLeftTitle = styled.div.attrs(() => ({
    className: `h5`
}))`
    margin-bottom: 0.2rem;
`;
const PlanBoxLeftText = styled.div`
    font-size: 14px;
`;
const PlanBoxRight = styled.div`
    
`;
const PlanBoxRightPrice = styled.div.attrs(() => ({
    className: `h4 text-primary`
}))`
    margin-bottom: 0;

    span {
        font-size: 1rem;
        font-weight: 500;
        color: var(--bs-black);
    }
`;
const BillingAddress = styled.div`
    padding: 1rem;
`;
const PaymentMethod = styled.div`
    padding: 1rem;
`;

interface PropTypes {
    trialWebsite: string,
    trialWebsiteTitle: string,
    trialUserEmail: string,
    trialUserName: string,
    trialUserPhone: string,
    planID: string
}

const PaymentCheckoutForm = (props: PropTypes) => {

    // Props
    const { trialWebsite, trialWebsiteTitle, trialUserEmail, trialUserName, trialUserPhone, planID } = props;

    // Functions
    const stripe = useStripe();
    const elements = useElements();

    const CardElementOption = {
        hidePostalCode: true,
        style: {
            base: {
                fontFamily: 'Rubik, sans-serif',
                fontSize: '16px',
                padding: '16px'
            }
        }
    }

    // State
    const [form03Validated, setForm03Validated] = useState(false);
    const [trialUserAddress, setTrialUserAddress] = useState<string>('');
    const [trialUserZip, setTrialUserZip] = useState<string>('');
    const [trialUserCountry, setTrialUserCountry] = useState<string>('');
    const [trialUserState, setTrialUserState] = useState<string>('');
    const [trialUserCity, setTrialUserCity] = useState<string>('');
    const [paymentMethodToken, setPaymentMethodToken] = useState<string>("");

    const createSubscription = async () => {
        try {
            // create a payment method
            const paymentMethod = await stripe?.createPaymentMethod({
                type: "card",
                card: elements?.getElement(CardElement)!
            });

            if (paymentMethod && paymentMethod.paymentMethod) {
                console.info("token ==>", paymentMethod);
                setPaymentMethodToken(paymentMethod.paymentMethod.id);
            }


        } catch (error) {
            console.log(error);
        }
    }

    // Step 02
    const handlePlanPaymentStepForm = (e: React.FormEvent<HTMLFormElement>) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            if(trialWebsite && trialWebsite !== '' && trialWebsiteTitle && trialWebsiteTitle !== '' && trialUserEmail && trialUserEmail !== '' && trialUserName && trialUserName !== '' && trialUserPhone && trialUserPhone !== '' && trialUserAddress && trialUserAddress !== '' && trialUserZip && trialUserZip !== '' && trialUserCountry && trialUserCountry !== '' && trialUserState && trialUserState !== '' && trialUserCity && trialUserCity !== '' && paymentMethodToken && paymentMethodToken !== '' && planID && planID !== '') {
                //signUpService(trialWebsite, trialWebsiteTitle, trialUserEmail, trialUserName, trialUserPhone, trialUserAddress, trialUserZip, trialUserCountry, trialUserState, trialUserCity, paymentMethodToken, planID );
            }
            
        }
        setForm03Validated(true);
    }

    return (
        <>
            <PaymentWrapper>
                <Form noValidate validated={form03Validated} onSubmit={(e) => handlePlanPaymentStepForm(e)}>
                    <PaymentWrapperBox>
                        <PaymentWrapperBoxTitle>Order Details</PaymentWrapperBoxTitle>
                        <OrderSummary>
                            <PlanBoxLeft>
                                <PlanBoxLeftTitle>Yearly Plan</PlanBoxLeftTitle>
                                <PlanBoxLeftText>Description Text</PlanBoxLeftText>
                            </PlanBoxLeft>
                            <PlanBoxRight>
                                <PlanBoxRightPrice>$22.99<span>/monthly</span></PlanBoxRightPrice>
                            </PlanBoxRight>
                        </OrderSummary>
                    </PaymentWrapperBox>

                    <PaymentWrapperBox>
                        <PaymentWrapperBoxTitle>Order Details</PaymentWrapperBoxTitle>
                        <BillingAddress>
                            <Row className='g-2'>
                                <Col sm={8}>
                                    <FloatingLabel controlId="trialUserAddress" label="Enter Address">
                                        <Form.Control type="text" placeholder="https://enabled.at" value={trialUserAddress} onChange={(e) => setTrialUserAddress(e.target.value)} required/>
                                        <Form.Control.Feedback type="invalid" tooltip>Please enter Billing Address.</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col sm={4}>
                                    <FloatingLabel controlId="trialUserZip" label="Enter Zip">
                                        <Form.Control type="text" placeholder="" value={trialUserZip} onChange={(e) => setTrialUserZip(e.target.value)} required />
                                        <Form.Control.Feedback type="invalid" tooltip>Please enter Zip Code.</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col sm={4}>
                                    <FloatingLabel controlId="trialUserCountry" label="Select Country">
                                        <Form.Control type="text" placeholder="" value={trialUserCountry} onChange={(e) => setTrialUserCountry(e.target.value)} required />
                                        {/* <Form.Select aria-label="Select Country">
                                            <option >U.S</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select> */}
                                        <Form.Control.Feedback type="invalid" tooltip>Please enter Country.</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col sm={4}>
                                    <FloatingLabel controlId="trialUserState" label="Select State">
                                        <Form.Control type="text" placeholder="" value={trialUserState} onChange={(e) => setTrialUserState(e.target.value)} required />
                                        {/* <Form.Select aria-label="Select State">
                                            <option >U.S</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select> */}
                                        <Form.Control.Feedback type="invalid" tooltip>Please enter State.</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col sm={4}>
                                    <FloatingLabel controlId="trialUserCity" label="Select City">
                                        <Form.Control type="text" placeholder="" value={trialUserCity} onChange={(e) => setTrialUserCity(e.target.value)} required />
                                        {/* <Form.Select aria-label="Select City">
                                            <option >U.S</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select> */}
                                        <Form.Control.Feedback type="invalid" tooltip>Please enter City.</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </BillingAddress>
                    </PaymentWrapperBox>
                    <PaymentWrapperBox>
                        <PaymentWrapperBoxTitle>Order Details</PaymentWrapperBoxTitle>
                        <PaymentMethod>
                            <Row className='g-2'>
                                <Col sm={12}>
                                    <CardElement options={CardElementOption} onChange={() => createSubscription()} />
                                </Col>
                            </Row>
                        </PaymentMethod>
                    </PaymentWrapperBox>
                    <Button variant="primary" type="submit" size='lg' className='w-100' disabled={!stripe}>Submit</Button>
                </Form>
            </PaymentWrapper>

        </>
    )
}

export default PaymentCheckoutForm;