import { Button, FloatingLabel, Form } from "react-bootstrap";
import UserModuleTemplate from "../../templates/UserModuleTemplate";
import styled from 'styled-components';
import { useState } from "react";

const ForgotPasswordWrapper = styled.div`
    width: 100%;
`;

const ForgotPasswordPage = () => {

    // State 
    const [userID, setUserId] = useState<string>('');

    return (
        <>
            <UserModuleTemplate
                templateTitle={`Forgot Your Password?`}
                templateType={3}
            >
                <ForgotPasswordWrapper>
                    <Form>

                        <FloatingLabel controlId="userID" label="Email address" className="mb-3">
                            <Form.Control type="email" placeholder="name@example.com" value={userID} onChange={(e) => setUserId(e.target.value)} />
                        </FloatingLabel>

                        <Button variant="primary" type="submit" size='lg' className='w-100'>Send</Button>

                    </Form>
                </ForgotPasswordWrapper>
            </UserModuleTemplate>

        </>
    )
}

export default ForgotPasswordPage;