import DashboardHeaderBlock from "../Components/DashboardHeader";
import MainTemplate from "./MainTemplate";
import styled from 'styled-components';


const SidebarTemplateWrapper = styled.div`
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-inline-end: 2rem;
    padding-inline-start: 22rem;
    position: relative;
`;
const DashboardHeader = styled.div``;
const DashboardPageContent = styled.div``;

interface PropType {
    children: any
}

const DashboardPagesTemplate = (prop: PropType) => {

    const { children } = prop;

    return (
        <>
            <MainTemplate>
                <SidebarTemplateWrapper>
                    <DashboardHeader>
                        <DashboardHeaderBlock />
                    </DashboardHeader>
                    <DashboardPageContent>
                        {children}
                    </DashboardPageContent>
                </SidebarTemplateWrapper>

            </MainTemplate>
        </>
    )
}

export default DashboardPagesTemplate;