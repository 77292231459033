import { useTranslation } from 'react-i18next';
import DashboardPagesTemplate from '../../templates/DashboardPagesTemplate';
import { Accordion, Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useState } from 'react';
import styled from 'styled-components';

// Style
const ProfileButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: sticky;
    bottom: 0;
    background-color: #f5f5f5;
`;

const ProfilePage = () => {

    const { t } = useTranslation();

    // Form Step 01
    const [isUserFormValidated, setIsUserFormValidated] = useState(false);


    // Save Profile
    const handleUserProfileUpdateForm = (e: React.FormEvent<HTMLFormElement>) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            // API Call Here
        }
        setIsUserFormValidated(true);
    }


    return (
        <>
            <DashboardPagesTemplate>
                <h1>Profile</h1>

                <Form noValidate validated={isUserFormValidated} onSubmit={(e) => handleUserProfileUpdateForm(e)}>

                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{t('Update Personal Information')}</Accordion.Header>
                            <Accordion.Body>
                                <Row className='gy-3'>
                                    <Col md={6}>
                                        <FloatingLabel controlId="userFirstName" label="First Name">
                                            <Form.Control type="text" placeholder="" required />
                                            <Form.Control.Feedback type="invalid" tooltip>Please first name.</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md={6}>
                                        <FloatingLabel controlId="userLastName" label="Last Name">
                                            <Form.Control type="text" placeholder="" required />
                                            <Form.Control.Feedback type="invalid" tooltip>Please last name.</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md={6}>
                                        <FloatingLabel controlId="userEmail" label="Last Name">
                                            <Form.Control type="email" placeholder="" required />
                                            <Form.Control.Feedback type="invalid" tooltip>Please enter valid email.</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md={6}>
                                        <FloatingLabel controlId="userPhone" label="Phone Number">
                                            <Form.Control type="tel" placeholder="" required />
                                            <Form.Control.Feedback type="invalid" tooltip>Please enter phone number.</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>{t('Update Website Information')}</Accordion.Header>
                            <Accordion.Body>
                                <Row className='gy-3'>
                                    <Col md={6}>
                                        <FloatingLabel controlId="trialWebsite" label="Website URL">
                                            <Form.Control type="url" placeholder="https://enabled.at" required />
                                            <Form.Control.Feedback type="invalid" tooltip>Please add website URL Like https://enabled.at or http://enabled.at.</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="trialWebsiteTitle" label="Website Title">
                                            <Form.Control type="text" placeholder="Enable Web Accessibility" required />
                                            <Form.Control.Feedback type="invalid" tooltip>Please enter your website title.</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>{t('Update Billing Address')}</Accordion.Header>
                            <Accordion.Body>
                                <Row className='gy-3'>
                                    <Col sm={8}>
                                        <FloatingLabel controlId="trialUserAddress" label="Enter Address">
                                            <Form.Control type="text" placeholder="https://enabled.at" required />
                                            <Form.Control.Feedback type="invalid" tooltip>Please enter Billing Address.</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={4}>
                                        <FloatingLabel controlId="trialUserZip" label="Enter Zip">
                                            <Form.Control type="text" placeholder="" required />
                                            <Form.Control.Feedback type="invalid" tooltip>Please enter Zip Code.</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={4}>
                                        <FloatingLabel controlId="trialUserCountry" label="Select Country">
                                            <Form.Control type="text" placeholder="" required />
                                            {/* <Form.Select aria-label="Select Country">
                                            <option >U.S</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select> */}
                                            <Form.Control.Feedback type="invalid" tooltip>Please enter Country.</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={4}>
                                        <FloatingLabel controlId="trialUserState" label="Select State">
                                            <Form.Control type="text" placeholder="" required />
                                            {/* <Form.Select aria-label="Select State">
                                            <option >U.S</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select> */}
                                            <Form.Control.Feedback type="invalid" tooltip>Please enter State.</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={4}>
                                        <FloatingLabel controlId="userCity" label="Select City">
                                            <Form.Control type="text" placeholder="" required />
                                            {/* <Form.Select aria-label="Select City">
                                            <option >U.S</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select> */}
                                            <Form.Control.Feedback type="invalid" tooltip>Please enter City.</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>{t('Update Password')}</Accordion.Header>
                            <Accordion.Body>
                                <Row className='gy-3'>
                                    <Col md={6}>
                                        <FloatingLabel controlId="userPassword" label="Password">
                                            <Form.Control type="password" placeholder="Password" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="userConfirmPassword" label="Confirm Password">
                                            <Form.Control type="password" placeholder="Password" />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <ProfileButtonWrapper>
                        <Button variant="primary" type="submit" size='lg'>Submit</Button>
                    </ProfileButtonWrapper>
                </Form>

            </DashboardPagesTemplate>

        </>
    )

}

export default ProfilePage;