import styled from 'styled-components';

// Base Style
import "../Assets/scss/base.scss";

// Style
const MainWrapper = styled.div`
    background-color: rgba(var(--bs-black-rgb), 0.04);
    min-height: 100vh;
    display: flex;
    width: 100%;
`;

interface PropType {
    children: any
}

const MainTemplate = (prop: PropType) => {

    const {children} = prop;

    return(
        <>
            <MainWrapper>
                {children}
            </MainWrapper>
        </>
    )
}

export default MainTemplate;