import { Button, FloatingLabel, Form } from "react-bootstrap";
import UserModuleTemplate from "../../templates/UserModuleTemplate";
import styled from 'styled-components';
import { useState } from "react";

const ForgotPasswordWrapper = styled.div`
    width: 100%;
`;

const ResetPasswordPage = () => {

    // State 
    const [userPassword, setUserPassword] = useState<string>('');
    const [userConfirmPassword, setUserConfirmPassword] = useState<string>('');

    return (
        <>
            <UserModuleTemplate
                templateTitle={`Reset Your Password?`}
                templateType={3}
            >
                <ForgotPasswordWrapper>
                    <Form>

                        <FloatingLabel controlId="userNewPassword" label="New Password" className='mb-3'>
                            <Form.Control type="password" placeholder="Password" value={userPassword} onChange={(e) => setUserPassword(e.target.value)} />
                        </FloatingLabel>

                        <FloatingLabel controlId="userConfirmPassword" label="Confirm Password" className='mb-3'>
                            <Form.Control type="password" placeholder="Password" value={userConfirmPassword} onChange={(e) => setUserConfirmPassword(e.target.value)} />
                        </FloatingLabel>

                        <Button variant="primary" type="submit" size='lg' className='w-100'>Done</Button>

                    </Form>
                </ForgotPasswordWrapper>
            </UserModuleTemplate>

        </>
    )
}

export default ResetPasswordPage;