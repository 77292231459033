// User Module
import LoginPage from '../Pages/UserModule/LoginPage';
import SignUpPage from '../Pages/UserModule/SignUpPage';
import ForgotPasswordPage from '../Pages/UserModule/ForgotPasswordPage';
import ResetPasswordPage from '../Pages/UserModule/ResetPasswordPage';

// Error Page
import ErrorPage from '../Pages/ErrorPage';

// Dashboard Pages
import Home from '../Pages/dashboard';
import ProfilePage from '../Pages/UserModule/Profile';


export const routesList = [
    {
        path: "/login",
        element: <LoginPage />,
        breadcrumb: "Login",
        protected: false,
        metaData: {
            title: "Dashboard | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/signup",
        element: <SignUpPage />,
        breadcrumb: "Login",
        protected: false,
        metaData: {
            title: "Dashboard | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/forgot-password",
        element: <ForgotPasswordPage />,
        breadcrumb: "Login",
        protected: false,
        metaData: {
            title: "Dashboard | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/reset-password",
        element: <ResetPasswordPage />,
        breadcrumb: "Login",
        protected: false,
        metaData: {
            title: "Dashboard | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/",
        element: <Home />,
        breadcrumb: "Dashboard",
        protected: true,
        metaData: {
            title: "Dashboard | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/profile",
        element: <ProfilePage />,
        breadcrumb: "Profile",
        protected: true,
        metaData: {
            title: "Profile | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/404",
        element: <ErrorPage />,
        breadcrumb: "404 Error",
        protected: false,
        metaData: {
            title: "404 Error | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }
]