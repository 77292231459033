import { Link } from "react-router-dom";
import LanguageButton from "../Components/LanguageButton";
import MainTemplate from "./MainTemplate";
import styled from 'styled-components';
import DashboardLogo from "../Assets/img/enabledat-logo.svg";
//import DashboardLogo from "../Assets/img/enabledat-logo-icon.svg";

// Style
const UserModuleWrapper = styled.div`
    display: flex;
    width: 100%;
`;

// Form Part Css
const UserModuleFormWrapper = styled.div`
    width: 40%;
    flex: 0 0 auto;
    background-color: var(--bs-white);
    display: flex;
    flex-direction: column;
    padding-inline: 2.5vw;
    padding-top: 2.5vw;
    padding-bottom: 2.5vw;
`;

const UserModuleFormWrapperHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const UserModuleFormWrapperBackButton = styled.button.attrs(() => ({
    className: 'btn btn-outline-primary',
}))`
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgba(var(--bs-primary-rgb), 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
`;

const UserModuleFormWrapperBackButtonIcon = styled.i.attrs(() => ({
    className: `material-symbols-outlined`
}))``;

const UserModuleFormWrapperStep = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
`;
const UserModuleFormWrapperStepCount = styled.div`
    
`;
const UserModuleFormWrapperStepText = styled.div`
    display: flex;
    align-items: center;

    &::before {
        content: "";
        position: relative;
        left: 0;
        background-color: rgba(var(--bs-danger-rgb), 1);
        display: inline-flex;
        width: 5rem;
        height: 2px;
        margin-inline: 0.8rem;
    }
`;


const UserModuleFormWrapperBody = styled.div`
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;
const UserModuleFormWrapperBodyLogo = styled.div`
    width: 350px;
    margin-bottom: 1rem;
`;
const UserModuleFormWrapperBodyTitle = styled.div`
    text-align: center;
    background: rgb(255,255,255);
    position: relative;
    margin-bottom: 3rem;

    &::before {
        content: "";
        display: inline-flex;
        margin-bottom: 1.5rem;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        max-width: 250px;
        width: 100%;
        height: 3px;
        background: -moz-linear-gradient(90deg, rgba(255,255,255,0.6) 0%, rgba(4,34,142,1) 50%, rgba(255,255,255,0.6) 100%);
        background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.6) 0%, rgba(4,34,142,1) 50%, rgba(255,255,255,0.6) 100%);
        background: linear-gradient(90deg, rgba(255,255,255,0.6) 0%, rgba(4,34,142,1) 50%, rgba(255,255,255,0.6) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    }

    h1 {
        margin-bottom: 0;
    }

    p {
        margin-top: 0.3rem;
    }
`;
const UserModuleFormWrapperBodyForm = styled.div`
    width: 100%;
`;

const UserModuleFormWrapperFooter = styled.div`
    & a {
        color: var(--bs-danger);
        font-weight: 500;
        position: relative;
        display: flex;
        align-items: center;

        &::before {
            content: "";
            position: relative;
            left: 0;
            background-color: rgba(var(--bs-danger-rgb), 1);
            display: inline-flex;
            width: 5rem;
            height: 2px;
            margin-inline-end: 1rem;
        }
    }
`;

const UserModuleVideoWrapper = styled.div`
    width: 60%;
    flex: 0 0 auto;
`;

interface PropType {
    children: any,
    templateType?: number,
    templateTitle?: string,
    templateSubTitle?: string,
    formStep?: number,
    hideFooter?: boolean
}

const UserModuleTemplate = (prop: PropType) => {

    const { children, templateType, templateTitle, templateSubTitle, formStep, hideFooter } = prop;

    return (
        <>
            <MainTemplate>
                <UserModuleWrapper>
                    <UserModuleFormWrapper>

                        <UserModuleFormWrapperHeader>
                            {templateType === 2 ? (
                                <>
                                    <UserModuleFormWrapperStep>
                                        <UserModuleFormWrapperStepCount>0{formStep ? formStep : `01`}</UserModuleFormWrapperStepCount>
                                        <UserModuleFormWrapperStepText>
                                            {formStep === 1 ? `Personal Details` : formStep === 2 ? `Select Plan` : formStep === 3 ? `Make a Payment` : `Thank You`}
                                        </UserModuleFormWrapperStepText>
                                    </UserModuleFormWrapperStep>
                                </>
                            ) : (
                                <>
                                    <UserModuleFormWrapperBackButton>
                                        <UserModuleFormWrapperBackButtonIcon>keyboard_backspace</UserModuleFormWrapperBackButtonIcon>
                                    </UserModuleFormWrapperBackButton>
                                </>
                            )}

                            <LanguageButton />
                        </UserModuleFormWrapperHeader>

                        <UserModuleFormWrapperBody>
                            <UserModuleFormWrapperBodyLogo>
                                {/* <img src={DashboardLogo} alt="" /> */}
                                Your Logo Here
                            </UserModuleFormWrapperBodyLogo>
                            <UserModuleFormWrapperBodyTitle>
                                <h1 className='h3 text-primary'>{templateTitle}</h1>
                                {templateSubTitle && (
                                    <>
                                        <p>{templateSubTitle}</p>
                                    </>
                                )}
                            </UserModuleFormWrapperBodyTitle>
                            <UserModuleFormWrapperBodyForm>
                                {children}
                            </UserModuleFormWrapperBodyForm>
                        </UserModuleFormWrapperBody>

                        {hideFooter ? (
                            <>

                            </>
                        ) : (
                            <>
                                <UserModuleFormWrapperFooter>
                                    {templateType === 1 ? (
                                        <>
                                            <Link to="/signup">Sign Up</Link>
                                        </>
                                    ) : (
                                        <>
                                            <Link to="/login">Login</Link>
                                        </>
                                    )}
                                </UserModuleFormWrapperFooter>
                            </>
                        )}

                    </UserModuleFormWrapper>
                    <UserModuleVideoWrapper></UserModuleVideoWrapper>
                </UserModuleWrapper>
            </MainTemplate >
        </>
    )
}

export default UserModuleTemplate;